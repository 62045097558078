.id7-utility-masthead {
  z-index: auto;
}

.id7-page-footer .id7-app-footer {
  background: white;
  color: #383838;

  a, a:link, a:visited {
    color: #5b3069;
  }
}

.btn + .btn {
  margin-left: 1rem;
}
