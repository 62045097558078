@import 'variables';
@import 'mixins';

.search-form {
  margin-bottom: 2rem;
  position: relative;

  .btn-vanilla {
    padding: 0;
    background-color: transparent;
    border: 0;

    &:focus {
      outline:none;
    }
  }

  .searchButton {
    position: absolute;
    top: 8px;
    right: 10px;
    color: @search-icon-color;
    cursor: pointer;
  }

  .clearButton {
    display: none;
  }

  ::-ms-clear {
    display: none;
  }

  .screen-xs-orless({
    .clearButton{
      position: absolute;
      top: 8px;
      right: 50px;
      color: @clear-icon-color;
      cursor: pointer;
      display: block;
    }
  })
}

.exam-papers-search-form {
  .form-group-lg {
    margin-bottom: 15px;
  }

  .text-left {
    text-align: left;
  }
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid @input-border;
  background-color: white;
  font-size: @font-size-large;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: @padding-large-vertical @padding-large-horizontal;
}

.react-autosuggest__suggestion--highlighted {
  background-color: @results-alt-background-color;
}
