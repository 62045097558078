@import 'variables';
@import 'mixins';

h1 img {
  position: absolute;
  right: 20px;
  top: 40px;
  height: 103px;
}

.form-table .row {
  margin-bottom: 0.75rem;
}

.screen-sm-orless({
  .form-table {
    .header.row {
      display: none;
    }

    .btn {
      font-size: 80%;
    }

    .row {
      border: solid 1px lightgray;
      padding: 1.5rem 0.5rem;
    }
  }
});

.masquerade-notice {
  background-color: #fc6;
  margin-bottom: 0;
  padding: .3em;
  text-align: center;
}
