@import 'variables';
@import 'mixins';

.search-results {
  padding: 0.75rem 0 0.75rem;

  h3, .more-results {
    margin: 0.5rem 0 1rem 0;
    padding-left: @result-text-padding;
    padding-right: @result-text-padding;
  }

  h3, h4 {
    color: @results-section-heading-color;
  }

  .results-footer {
    font-size: 1.3rem;
    line-height: 1.4;
    color: @result-metadata-color;
    padding: 0 @result-text-padding 0 @result-text-padding;
  }

  &:hover {
    background-color: @results-section-highlight-color;
  }

  &:not(:last-of-type) {
    border-bottom: 0.3rem dotted @results-section-ruler-color;
  }

  .no-more-results {
    padding-left: 1rem;
    color: @results-no-more-color;
    font-size: 1.3rem;

    .keyframes(fade-out; {
      0% { opacity: 1; }
      100% { opacity: 0; }
    });

    .keyframes(slide-up; {
      0% { }
      100% { height: 0; margin: 0 }
    });

    .animation(~"fade-out 1500ms ease-out 2000ms 1 normal forwards, slide-up 500ms ease-in-out 3500ms 1 normal forwards");
  }

  .popover-content {
    height: 60vh;
  }

  .popover {
    max-height:70vh;
    max-width:90%;
  }

  .popover-content pre {
    max-height: 100%;
  }
}

.search-result {
  padding: 1rem @result-text-padding;
  margin-bottom: 0;

  &:nth-of-type(even){
    background-color: @results-alt-background-color;
  }

  .hsep, .summary {
    line-height: 1.4;
  }

  > img {
    // hide unstyled flash during load of <RemoteImage />
    display: none;
  }

  h4 {
    font-size: 1.75rem;
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      text-decoration: none;
      color: @result-heading-link-color;

      &:visited {
        color: @result-heading-visited-link-color;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .link {
    cite {
      display: none;
    }
  }

  .link, .summary, .metadata {
    font-size: 1.5rem;
  }

  .summary {
    word-wrap: break-word;
    color: @result-summary-color;

    .role + .dept::before {
      content: ', ';
    }

    .contact {
      display: block;

      .email + .phone {
        margin-left: 1rem;
      }
    }

    .exam-papers {
      td, th {
        width:20%;
      }

      ul {
        list-style: none;
        padding-left: 0px;
      }
    }

    .thumbnail-link {
      display: inline-block;
      margin-right: 1rem;

      img {
        max-width: 100%;
        max-height: 200px;
        border: solid 1px @result-thumbnail-border-color;
        margin: 0.25rem;
        vertical-align: top;
      }
    }

    match, .highlight {
      font-weight: bold;
      background-color: @result-highlight-background-color;
    }
  }

  .course-code {
    font-weight: bold;
    color: @result-course-code-color;
  }

  .metadata {
    font-size: 1.3rem;
    color: @result-metadata-color;
    display: inline-block;
    margin-right: 10px;
  }

  .explanation {
    display:inline-block;
  }
}

.search-result.exam-result {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.suggested-links {
  .suggestions-card {
    margin-bottom: 1rem;
    padding: 0;

    &:nth-of-type(even) {
      padding-left: 1rem;
    }
    &:nth-of-type(odd) {
      padding-right: 1rem;
    }
  }

  .search-result {
    margin: 0;
    padding: 0.5rem;
    border: solid 1px @card-rule-color;
    background-color: @card-background-color;
    box-shadow: 0 1px 2px @card-shadow;
    border-radius: 2px;

    h4 {
      font-size: 1.5rem;
      line-height: 1.4;
    }

    .link, .summary {
      font-size: 1.3rem;
    }

    .metadata {
      font-size: 1.2rem;
    }
  }
}

.fetch-error {
  margin: 1rem 0;
  padding: 1rem;
  border: solid 1px  @card-background-color;
  box-sizing: border-box;
}

.table.exam-papers {
  th {
    padding-bottom: 3px;
  }
  td {
    padding-top: 5px;
    padding-bottom: 0;
  }

  margin-bottom: 0;
}

.citeUrl {
  color: @result-link-color;
}

.results-info {
  padding: 0.75rem;
  font-size: 1.75rem;
  background-color: @results-alt-background-color;

  .cancel {
    float:right;
  }

  .description {
    padding: 0.5rem;
  }

  ul.scopes {
    display: inline;
    list-style: none;
    padding-left: 0;

    li {
      display: inline;
    }

    li + li:before {
      content: ", ";
    }
  }
}

.reflected-query {
  display: inline-block;
  line-height: 1.2;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: -3px;
  white-space: nowrap;
}

.screen-xs-orless({
  .fetch-error {
    margin: 1rem 0;
    padding: 0;
    border: none;

    p, h6 {
      display: none;
    }
  }

  .search-results:hover {
    background-color: transparent;
  }

  .search-result {
    margin: 1.5rem 0;
    padding: 0.75rem;
    background-color: @card-background-color;
    box-shadow: 0 1px 2px @card-shadow;
    border-radius: 2px;

    &:nth-of-type(even){
      background-color: @card-background-color;
    }

    h4 a {
      line-height: 1.4;
      display: inline;
      overflow: auto;
      white-space: normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-all;
      word-break: break-word;
      hyphens: none;
    }

    .hsep {
      border-bottom: solid 1px @card-rule-color;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .link {
      .url {
        display: none;
      }

      cite {
        display: block;
        font-style: normal;
      }
    }

    .summary .table {
      width: 100%;
      border-width: 0 1px 1px 1px solid @card-rule-color;
      background-color: @card-table-color;
      margin-top: -0.6rem; // lift over the .hsep

      thead {
        display: none;
      }

      tr:nth-of-type(even) {
        background-color: inherit;
      }

      tbody td {
        display: block;
      }

      tbody td:before {
        content: attr(data-th);
        display: block;
        font-weight: bold;
      }

      tbody td, tbody th {
        width: 100%;
      }

      tbody ul {
        margin: 0;
      }

      &.exam-papers {
        li {
          display: inline;
          word-break: break-all;
          margin-right: 0.75rem;

          &:after {
            color: @card-separator-color;
            margin-left: 0.75rem;
            content: "|";
          }

          &:last-of-type {
            margin-right: 0;

            &:after {
              content: "";
            }
          }
        }
      }
    }
  }

  .suggested-links {
    .suggestions-card:nth-of-type(n) {
      padding: 0;
    }
  }
});

@media screen and (min-width: @screen-md-min) {
  // affecting mywarwick suggested results only
  body.in-search .suggested-links {
    ul.results, li.search-result {
      display: flex;
      flex-wrap: wrap;
    }

    .search-result {
      div {
        overflow:hidden;
      }

      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}