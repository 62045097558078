.welcome-hint {
  font-size: 2rem;
  color: #666;
  margin: 1rem 0 2.5rem;

  .screen-sm-orless({
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    line-height: 1.4;
  });
}
